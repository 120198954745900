module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.whiteclawzero.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wc-zero-dev","accessToken":"MC5aUjhaT0JFQUFDRUFpRTlh.77-977-9Cu-_ve-_ve-_vX7vv70NGu-_ve-_ve-_ve-_ve-_ve-_ve-_vSRjD--_ve-_ve-_vXjvv73vv73vv70-77-9KiF6","promptForAccessToken":true,"apiEndpoint":"https://wc-zero-dev.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","display":"minimal-ui","icon":"/codebuild/output/src2756206294/src/codestar-connections.us-west-2.amazonaws.com/git-http/913574454551/us-west-2/3213ba38-ea3a-464b-81b2-65f8faeff1a5/MarkAnthonyGroup/www.whiteclawzero.com/src/assets/images/favicon-icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1a15230b4c6525c06a1e4f8847912fc9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W48BC7FZ","includeInDevelopment":true,"enableWebVitalsTracking":true,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            pageType: window.pageType,\n          }\n        }"},"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
